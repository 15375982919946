




















































import PageLayout from '@/components/page/layout.vue';
import PuppeteerSender from '@/components/puppeteer/sender/index.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import TableNavigation from '@/components/tables/navigation.vue';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { carCardsModule, humanCardsModule } from '@/store';
import { CardType, getEnabledCardTypes } from '@/store/cards/cards';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

@Component({
  components: { TableNavigation, PageLayout, TableGenerator, PuppeteerSender }
})
export default class Cards extends Vue {
  isSenderVisible = false;
  tab: CardType = CardType.None;
  isLoading = false;

  get state(): BaseItemsStateModule<any, any> {
    try {
      switch (this.tab) {
        case CardType.Car:
          return carCardsModule;
        case CardType.Human:
        default:
          return humanCardsModule;
      }
    } catch (e) {
      return carCardsModule;
    }
  }

  get stats() {
    try {
      switch (this.tab) {
        case CardType.Car:
          return this.$store.state.stats.cards.car;
        case CardType.Human:
        default:
          return this.$store.state.stats.cards.human;
      }
    } catch (e) {
      return {};
    }
  }

  get schema(): ITableColumn[] {
    const result: ITableColumn[] = [
      { label_i18n: 'id', prop: 'id', 'class-name': 'events-table__id-cell', width: 80, sortable: 'custom' },
      { label_i18n: 'image', 'class-name': 'cell_without_paddings', component: TableComponentNames.CardObjectThumbnail, width: 170, cardType: this.tab },
      {
        label_i18n: 'cluster',
        'class-name': 'cell_without_paddings cluster-cell',
        component: TableComponentNames.CardClusterThumbnail,
        width: 170,
        cardType: this.tab
      },
      { label_i18n: 'description', component: TableComponentNames.CardDescription, 'min-width': 200, cardType: this.tab },
      { label_i18n: 'active,,1', width: 120, component: TableComponentNames.ActiveComponent, changeHandler: this.toggleActive },
      { label_i18n: 'filled,,1', width: 120, component: TableComponentNames.FilledComponent },
      { label_i18n: 'was_updated,,2', prop: 'modified_date', component: TableComponentNames.DateTime, width: 160 }
    ];
    return result;
  }

  get selectedItemsIds() {
    return this.state.selectedItems.map((v) => v.id);
  }

  get globalConfig() {
    return this.$store.state.config;
  }

  get tabs() {
    return getEnabledCardTypes(this.globalConfig).map((i) => ({ name: i, i18n: i + '_cards' }));
  }

  get isMonitoringAvailable() {
    return this.$store.getters.puppeteer && this.tab !== CardType.Car;
  }

  created() {
    const cardType = (this.$route?.meta?.type as CardType) || CardType.Human;
    this.tab = cardType;
  }

  mounted() {
    this.$store.dispatch('getStats', 'cards');
    this.loadMetaFieldDicts();
  }

  loadMetaFieldDicts() {
    const needToLoad = this.hasMetaFields && !this.$store.state.dicts.loaded_date;
    if (needToLoad) this.$store.dispatch(this.$store.state.dicts.Action.Get);
  }

  get hasMetaFields(): boolean {
    return !!(this.$store.state.config.human_card || this.$store.state.config.car_card);
  }

  beforeDestroy() {}

  tabChangeHandler(v: CardType, p: CardType) {
    let route: RawLocation = this.state.getListRoute();
    this.$router.replace(route);
    this.state.get();
  }

  rowClickHandler({ item, column }) {
    const route: RawLocation = this.state.getItemRoute(item);
    this.$router.push(route);
  }

  createHandler() {
    this.$router.push(this.state.getNewItemRoute());
  }

  async deleteHandler() {
    this.isLoading = true;
    for (let item of this.state.selectedItems) {
      try {
        await this.state.delete(item.id);
      } catch (e) {
        console.warn('delete.error ', e);
      }
    }
    await this.state.get();
    await this.$store.dispatch('getStats', 'cards');
    this.isLoading = false;
  }

  async toggleActive(item: any, value: boolean) {
    try {
      await this.state.update({ id: item.id, active: value });
      await this.$store.dispatch('getStats', 'cards');
      this.$showSuccess({ message: this.$tf(['common.action', 'common.success']) });
      return true;
    } catch (e) {
      this.$showError(new Error(e));
      return false;
    }
  }

  async removeFromMonitoring(ids) {
    await this.$store.dispatch('removeFromMonitoring', { cardIDs: ids, vueContext: this });
    this.state.items = [];
    await this.state.get();
  }

  async puppeteerSentHandler(allPromises) {
    this.isSenderVisible = false;
    this.state.items = [];
    await this.state.get();
  }
}
